import MailIcon from '@mui/icons-material/Mail';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { SxProps, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, useMemo } from 'react';
import { PlainLink } from '@components/Links';
import { openDialog } from '@config/axeptio';
import { CONTACT_EMAIL } from '@utils/consts/livv';
import { CONTACT_URL } from '@utils/consts/urls';
import useUser from '@utils/context/user';
import { sxs } from '@utils/mui/sx';

export interface FooterLink {
    href: string;
    title: string;
    onClick?: () => void;
}

const legalLinks = (textColor: string) => ({
    color: textColor,
    fontSize: 'body2.fontSize',
    fontWeight: 'medium',
});

const buttonLegalLinks = () => ({
    '&:hover': {
        background: 'none',
        textDecoration: 'underline',
    },
    p: 0,
});

const footerLinks: FooterLink[] = [
    { href: '/cgu', title: 'CGU' },
    { href: '/cgv', title: 'CGV' },
    { href: '/legal-notice', title: 'legalNotices' },
    { href: '/sitemap/decisions', title: 'sitemap' },
];

const footerSx: Record<string, SxProps> = {
    content: {
        display: 'flex',
        flexDirection: { tablet: 'row', xs: 'column' },
        gap: { md: 4, xs: 2 },
    },
    home: { backgroundColor: { tablet: 'transparent', xs: 'grey.100' } },
};

const Footer: FC = () => {
    const router = useRouter();
    const { t } = useTranslation('footer');
    const { info: user, privileges } = useUser();
    const isAllowedHomePage = router.pathname === '/' && privileges?.isAllowedToConsumePaidContent;
    const textColor = isAllowedHomePage ? 'grey.700' : 'text.secondary';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });

    const increaseMarginTop = ['/password-recovery', '/signin', '/signup'].some((value) =>
        router.pathname.startsWith(value),
    );

    const containerSx = useMemo(() => {
        const base: SxProps[] = [
            {
                backgroundColor: 'grey.700',
                mt: increaseMarginTop ? { xs: 38 } : 0,
                px: 0,
                py: { md: 2, xs: 3 },
            },
        ];

        if (isAllowedHomePage) {
            base.push(footerSx.home);
        }

        return sxs(base);
    }, [increaseMarginTop, isAllowedHomePage]);

    return (
        <Box component="footer" data-test-id="test-footer" sx={containerSx}>
            <Container
                maxWidth={false}
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Typography sx={{ color: textColor }} variant="body2">
                    {t('copyright', { currentYear: new Date().getFullYear() })}
                </Typography>
                <Box sx={footerSx.content}>
                    <Grid alignItems="center" display="flex" my={isMobile ? 4 : 0}>
                        <MailIcon sx={{ color: textColor, fontSize: 14 }} />
                        &nbsp;
                        <a href={CONTACT_URL} rel="noopener noreferrer" target="_blank">
                            <Typography sx={{ color: textColor }} variant="body2">
                                {CONTACT_EMAIL}
                            </Typography>
                        </a>
                    </Grid>
                </Box>
                <Box sx={footerSx.content}>
                    {!user && (
                        <Button
                            data-test-id="cookiesSettingsButton"
                            onClick={openDialog}
                            sx={sxs([legalLinks(textColor), buttonLegalLinks])}
                        >
                            {t('cookiesSettings')}
                        </Button>
                    )}
                    {footerLinks.map(({ href, onClick, title }) => (
                        <PlainLink
                            key={href}
                            href={href}
                            onClick={onClick}
                            sx={legalLinks(textColor)}
                            underline="hover"
                        >
                            {t(title)}
                        </PlainLink>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
